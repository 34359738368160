import { Component } from "@angular/core";

@Component({
  selector: "vc-application-container",
  templateUrl: "./application-container.component.html",
  styleUrls: ["./application-container.component.scss"],
})
export class ApplicationContainerComponent {
  public title = "verum-client";
}
