<vc-slide-up-mobile-dialog [heading]="heading" (closeClick)="closeClick.emit()" [isOpen]="isOpen">
  <div class="dropdown-mobile-options">
    <vc-text-input class="dropdown-mobile-options__input" [ngModel]="searchTerm" (ngModelChange)="filterItems($event)" #mobileSearchInput [placeholder]="placeholder">
      <ng-template #suffixTemplate>
        <vc-suffix-icon name="search"></vc-suffix-icon>
      </ng-template>
    </vc-text-input>
    <div class="p-2 cursor-pointer" *ngIf="allowAddItem && searchTerm && filteredItems.length < 1"  (click)="onAddItemClick(searchTerm)">
      Add "{{ searchTerm }}" to list
    </div>
    <div class="dropdown-mobile-options__list">
      <div *ngFor="let item of filteredItems" (click)="onItemClick(item)">
        <div *ngTemplateOutlet="dropItemTemplate, context: { $implicit: item }">
        </div>
      </div>
    </div>
  </div>
</vc-slide-up-mobile-dialog>
