import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { catchError, map, shareReplay, takeUntil } from "rxjs/operators";
import { AuthApi } from "../api/auth.api";
import { LoginResponseDto, SignupDto } from "../api/auth.models";
import { CompanyResponseDto } from "../model";
import { UserDto } from "../model/user/user.dto";


@Injectable({ providedIn: "root" })
export class AuthService {
  public signupDto: SignupDto;

  private loginResponse$: Observable<LoginResponseDto>;
  private user$: Observable<UserDto>;
  private company$: Observable<CompanyResponseDto>

  private forceLogout$ = new Subject<void>();

  constructor(
    private authApi: AuthApi,
  ) { }

  public get currentUser$(): Observable<UserDto> {
    if (!this.user$) {
      this.loginResponse$ = this.authApi.current().pipe(
        shareReplay(1),
        takeUntil(this.forceLogout$),
      );

      this.user$ = this.loginResponse$.pipe(
        map(r => r.userDto),
      );

      this.company$ = this.loginResponse$.pipe(
        map(r => r.companyDto),
      );
    }

    return this.user$;
  }

  public get currentCompany$(): Observable<CompanyResponseDto> { return this.company$ }

  public forceLogoutCurrentUser(): void {
    this.forceLogout$.next();
    this.user$ = null;
  }

  public isAuthenticated(): Observable<boolean> {
    return this.currentUser$.pipe(
      map(userDto => {
        // TODO: uncomment logic when user activation via email has been activated on BE
        // if (userDto?.state === "ACTIVE") {

        //   return true;
        // }
        // throw new Error("User is not authenticated.");
        return true;
      }),
      catchError(err => {
        throw err;
      }),
    );
  }
}
