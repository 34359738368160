<div class="overlay" *ngIf="isOpen" [@fade]>
  <div class="dialog" [@popup]="false">
    <div class="header">
      <div class="heading">{{heading}}</div>
      <span class="material-icons close-button cursor-pointer" (click)="closeClick.emit()">close</span>
    </div>
    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
