import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Attachment } from "../interfaces/attachment";
import { HttpService } from "../services/http.service";

const API_NAME = "/gcs";

@Injectable({
  providedIn: "root",
})
export class GcsApi {

  constructor(private httpService: HttpService) { }

  public uploadTempFile(formData: FormData, makeSquare: boolean = false): Observable<Attachment> {
    return this.httpService.post<FormData, Attachment>(`${API_NAME}/upload-file`, formData, new HttpParams()
      .append("makeSquare", String(makeSquare)));
  }

  public downloadTempFileUrl(folderName: string, fileName: string): string {
    return this.httpService.apiUrl(`${API_NAME}/${folderName}/${fileName}`);
  }
}
