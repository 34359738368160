import { Directive, EmbeddedViewRef, Input, OnDestroy, TemplateRef, ViewContainerRef } from "@angular/core";
import { Subscription } from "rxjs";
import { MediaQueryService } from "../services/media-query.service";

@Directive({
  selector: "[vcIfMedia]",
})
export class IfMediaDirective implements OnDestroy {
  private viewRef: EmbeddedViewRef<null>;
  private elseViewRef: EmbeddedViewRef<null>;
  private elseTemplateRef: TemplateRef<null>;

  private observeSubscription: Subscription;

  constructor(
    private templateRef: TemplateRef<null>,
    private viewContainer: ViewContainerRef,
    private mediaQueryService: MediaQueryService,
  ) { }

  @Input()
  public set vcIfMedia(media: string) {
    this.observeSubscription && this.observeSubscription.unsubscribe();
    this.observeSubscription = this.mediaQueryService.subscribeToMedia(media)
      .subscribe(value => this.updateView(value.matches));
  }

  @Input()
  public set vcIfMediaElse(templateRef: TemplateRef<null>) {
    this.elseTemplateRef = templateRef;
    this.elseViewRef = undefined;
  }

  public ngOnDestroy(): void {
    this.observeSubscription && this.observeSubscription.unsubscribe();
  }

  private updateView(matches: boolean): void {
    if (matches) {
      if (!this.viewRef) {
        this.viewContainer.clear();
        this.elseViewRef = undefined;
        this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else {
      if (!this.elseViewRef) {
        this.viewContainer.clear();
        this.viewRef = undefined;
        this.elseTemplateRef && this.viewContainer.createEmbeddedView(this.elseTemplateRef);
      }
    }
  }
}
