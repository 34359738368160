import { Component } from '@angular/core';
import { Confirmation, DialogService } from '../../services';

@Component({
  selector: 'vc-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  showModal = false;

  public get confirmation(): Confirmation { return this.dialogService.confirmation; };
  public get isAcceptButtonLoading() { return this.dialogService.isAcceptButtonLoading };

  constructor(private dialogService: DialogService) { }

  public onAcceptClicked() {
    this.dialogService.accept();
    this.closeModal();
  }

  public onRejectClicked() {
    this.dialogService.reject();
    this.closeModal();
  }

  openModal(): void {
    this.showModal = true;
  }

  closeModal(): void {
    this.showModal = false;
  }

}
