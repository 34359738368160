import { Component } from "@angular/core";

@Component({
  selector: "vc-layout-with-menu",
  templateUrl: "./layout-with-menu.component.html",
  styleUrls: ["./layout-with-menu.component.scss"],
})
export class LayoutWithMenuComponent {
  constructor() { }
}
