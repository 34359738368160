import { Component, Input, Optional } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { ControlOnChangeFn, ControlOnTouchedFn } from "../../../interfaces/controls";

@Component({
  selector: "vc-checkbox-input",
  templateUrl: "./checkbox-input.component.html",
  styleUrls: ["./checkbox-input.component.scss"],
})
export class CheckboxInputComponent implements ControlValueAccessor {

  constructor(
    @Optional() private ngControl: NgControl,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }
  @Input()
  public isInvalid = false;

  public value: boolean;

  private onChane: ControlOnChangeFn<boolean>;
  private onTouchedFn: ControlOnTouchedFn;

  public onInputChange(checked: boolean): void {
    this.value = checked;

    this.onChane && this.onChane(checked);
  }

  public writeValue(value: boolean): void {
    this.value = value;
  }
  public registerOnChange(fn: ControlOnChangeFn<boolean>): void {
    this.onChane = fn;
  }
  public registerOnTouched(fn: ControlOnTouchedFn): void {
    this.onTouchedFn = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }
}
