import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import * as Sentry from "@sentry/angular-ivy";

import { environment } from "../../environments/environment";
import "../shared/functions/string.functions";
import { WithCredentialsInterceptor } from "../shared/interceptors/with-credentials.interceptor";
import { SharedModule } from "../shared/shared.module";
import { ApplicationContainerComponent } from "./components/application-container/application-container.component";
import { CoreRoutingModule } from "./core-routing.module";

@NgModule({
  declarations: [
    ApplicationContainerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreRoutingModule,
    SharedModule,
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production && false}),
  ],
  bootstrap: [ApplicationContainerComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WithCredentialsInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class CoreModule { }
