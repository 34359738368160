<vc-dropdown [items]="items"
             [filterWith]="filterWith"
             [(ngModel)]="selected"
             heading="Select Country code"
             [isInvalid]="getIsInvalid()"
             (ngModelChange)="onPrefixChange()">
  <ng-template #itemDesktopTemplate let-item>
    <vc-default-desktop-item>
      <div *ngTemplateOutlet="itemTemplate, context: { $implicit: item }"></div>
    </vc-default-desktop-item>
  </ng-template>

  <ng-template #itemMobileTemplate let-item>
    <vc-default-mobile-item [item]="item" [selected]="selected">
      <div *ngTemplateOutlet="itemTemplate, context: { $implicit: item }"></div>
    </vc-default-mobile-item>
  </ng-template>
</vc-dropdown>

<ng-template #itemTemplate let-item>
  <div class="item">
    <div class="item__label">{{getCountry(item)}}</div>
    <div class="item__value"> {{item}}</div>
  </div>
</ng-template>
