import { Component, Optional } from "@angular/core";
import { ControlValueAccessor, NgControl, ValidationErrors } from "@angular/forms";
import { ControlOnChangeFn, ControlOnTouchedFn } from "../../interfaces/controls";
import { PHONE_PREFIXES } from "../../interfaces/phone-prefix";
import { DropdownComponentFilterWithFn } from "../dropdown/dropdown.component";

@Component({
  selector: "vc-phone-prefix-dropdown",
  templateUrl: "./phone-prefix-dropdown.component.html",
  styleUrls: ["./phone-prefix-dropdown.component.scss"],
})
export class PhonePrefixDropdownComponent implements ControlValueAccessor {
  public selected: string;

  public items: string[] = PHONE_PREFIXES.map(p => p.prefix);

  private onChangeFn: ControlOnChangeFn<string>;
  private onTouchedFn: ControlOnTouchedFn;

  public filterWith: DropdownComponentFilterWithFn<string> = (item, term) =>
    item && PHONE_PREFIXES.filter(p => p.country.toLowerCase().includes(term.toLowerCase())).some(p => p.prefix === item)

  constructor(
    @Optional() private ngControl: NgControl,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  public onPrefixChange(): void {
    this.onChangeFn && this.onChangeFn(this.selected);
  }

  public writeValue(prefix: string): void {
    if (prefix) {
      this.selected = prefix;
    }
  }
  public registerOnChange(fn: ControlOnChangeFn<string>): void {
    this.onChangeFn = fn;
  }
  public registerOnTouched(fn: ControlOnTouchedFn): void {
    this.onTouchedFn = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  public getIsInvalid(): boolean {
    return this.ngControl?.touched && this.ngControl?.invalid;
  }

  public get errors(): ValidationErrors {
    return this.ngControl.errors;
  }

  public getCountry(prefix: string): string {
    return PHONE_PREFIXES.find(p => p.prefix === prefix)?.country;
  }
}
