import { Component, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "vc-link-switch",
  templateUrl: "./link-switch.component.html",
  styleUrls: ["./link-switch.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LinkSwitchComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
