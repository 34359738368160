import { Directive, HostBinding, HostListener } from "@angular/core";

@Directive({
  selector: "[vcDragover]",
})
export class DragoverDirective {

  @HostBinding("class.dragover") public dragover = false;

  @HostListener("dragover", ["$event"])
  public onDragOver(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.dragover = true;
  }

  @HostListener("dragleave", ["$event"])
  public onDragLeave(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.dragover = false;
  }

  @HostListener("drop", ["$event"])
  public onDrop(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.dragover = false;
  }
}
