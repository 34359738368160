<input
  [type]="type"
  step="{{type === 'number' ? '0.01' : '1'}}"
  [attr.placeholder]="placeholder"
  [class.isInvalid]="getIsInvalid()"
  [class.uppercase]="uppercase"
  [value]="value || null"
  [attr.maxlength]="maxlength"
  [class.has-suffix-template]="!!suffixTemplate"
  [class.has-prefix-template]="!!prefixTemplate"
  [attr.was-password]="wasPassword"
  [readOnly]="readonly"
  (input)="onInput($event.target.value)"
  (blur)="onBlur()"
/>

<div *ngIf="type === 'password' || showPassword" class="postfix">
  <div class="password-eye" (click)="onEyeClicked()">
    <ng-container
      *ngTemplateOutlet="showPassword ? eyeOffIcon : eyeIcon"
    ></ng-container>
  </div>
</div>

<div *ngTemplateOutlet="suffixTemplate"></div>
<div *ngTemplateOutlet="prefixTemplate"></div>

<ng-template #eyeIcon>
  <svg
    width="20px"
    height="22px"
    viewBox="0 0 20 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Stylesheet"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Desktop-fields" transform="translate(-1365.000000, -323.000000)">
        <g
          id="Field/Desktop/Empty-Copy-13"
          transform="translate(1063.000000, 313.000000)"
        >
          <g id="visibility" transform="translate(302.000000, 10.500000)">
            <polygon id="Path" points="0 0 20 0 20 21 0 21"></polygon>
            <path
              d="M10,3.9375 C5.83333333,3.9375 2.275,6.65875 0.833333333,10.5 C2.275,14.34125 5.83333333,17.0625 10,17.0625 C14.1666667,17.0625 17.725,14.34125 19.1666667,10.5 C17.725,6.65875 14.1666667,3.9375 10,3.9375 Z M10,14.875 C7.7,14.875 5.83333333,12.915 5.83333333,10.5 C5.83333333,8.085 7.7,6.125 10,6.125 C12.3,6.125 14.1666667,8.085 14.1666667,10.5 C14.1666667,12.915 12.3,14.875 10,14.875 Z M10,7.875 C8.61666667,7.875 7.5,9.0475 7.5,10.5 C7.5,11.9525 8.61666667,13.125 10,13.125 C11.3833333,13.125 12.5,11.9525 12.5,10.5 C12.5,9.0475 11.3833333,7.875 10,7.875 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</ng-template>

<ng-template #eyeOffIcon>
  <svg
    width="20px"
    height="22px"
    viewBox="0 0 20 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Stylesheet"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Desktop-fields" transform="translate(-976.000000, -323.000000)">
        <g
          id="Field/Desktop/Empty-Copy-12"
          transform="translate(674.000000, 313.000000)"
        >
          <g
            id="visibility_off-24px"
            transform="translate(302.000000, 10.500000)"
          >
            <path
              d="M0,0 L20,0 L20,21 L0,21 L0,0 Z M0,0 L20,0 L20,21 L0,21 L0,0 Z M0,0 L20,0 L20,21 L0,21 L0,0 Z M0,0 L20,0 L20,21 L0,21 L0,0 Z"
              id="Shape"
            ></path>
            <path
              d="M10,6.125 C12.3,6.125 14.1666667,8.085 14.1666667,10.5 C14.1666667,11.06875 14.0583333,11.6025 13.8666667,12.10125 L16.3,14.65625 C17.5583333,13.55375 18.55,12.1275 19.1583333,10.5 C17.7166667,6.65875 14.1583333,3.9375 9.99166667,3.9375 C8.825,3.9375 7.70833333,4.15625 6.675,4.55 L8.475,6.44 C8.95,6.23875 9.45833333,6.125 10,6.125 Z M1.66666667,3.73625 L3.56666667,5.73125 L3.95,6.13375 C2.56666667,7.2625 1.48333333,8.7675 0.833333333,10.5 C2.275,14.34125 5.83333333,17.0625 10,17.0625 C11.2916667,17.0625 12.525,16.8 13.65,16.3275 L14,16.695 L16.4416667,19.25 L17.5,18.13875 L2.725,2.625 L1.66666667,3.73625 Z M6.275,8.575 L7.56666667,9.93125 C7.525,10.115 7.5,10.3075 7.5,10.5 C7.5,11.9525 8.61666667,13.125 10,13.125 C10.1833333,13.125 10.3666667,13.09875 10.5416667,13.055 L11.8333333,14.41125 C11.275,14.7 10.6583333,14.875 10,14.875 C7.7,14.875 5.83333333,12.915 5.83333333,10.5 C5.83333333,9.80875 6,9.16125 6.275,8.575 L6.275,8.575 Z M9.86666667,7.8925 L12.4916667,10.64875 L12.5083333,10.50875 C12.5083333,9.05625 11.3916667,7.88375 10.0083333,7.88375 L9.86666667,7.8925 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</ng-template>
