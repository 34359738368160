import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CurrencyDto, UserDto } from '../model';
import { HttpService } from '../services/http.service';
import {
  ForgottenPasswordDto,
  LoginDto,
  LoginResponseDto,
  MobileVerificationDto,
  SetupMobileNumberDto,
  SignupDto,
  SignupResponseDto,
} from './auth.models';

const API_NAME = '/auth';

@Injectable({ providedIn: 'root' })
export class AuthApi {
  constructor(private httpService: HttpService) {}

  public current(): Observable<LoginResponseDto> {
    return this.httpService.get(`${API_NAME}/current`);
  }

  public signup(dto: SignupDto): Observable<SignupResponseDto> {
    return this.httpService.post(`${API_NAME}/signup`, dto);
  }

  public login(dto: LoginDto): Observable<LoginResponseDto> {
    return this.httpService.post(`${API_NAME}/login`, dto);
  }

  public verifySmsCode(params: MobileVerificationDto): Observable<LoginResponseDto> {
    return this.httpService.get(`${API_NAME}/verifySmsCode`, params);
  }

  public verify2faCode(params: MobileVerificationDto): Observable<LoginResponseDto> {
    return this.httpService.get(`${API_NAME}/verify2faCode`, params);
  }

  public setupMobileNumber(dto: SetupMobileNumberDto) {
    return this.httpService.post(`${API_NAME}/setupMobileNumber`, dto);
  }

  public requestNewSmsCode() {
    return this.httpService.get(`${API_NAME}/requestNewSmsCode`);
  }

  public forgottenPassword(dto: ForgottenPasswordDto): Observable<void> {
    return this.httpService.post(`${API_NAME}/forgotten-password`, dto);
  }
}
