import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'vc-default-mobile-item',
  templateUrl: './default-mobile-item.component.html',
  styleUrls: ['./default-mobile-item.component.scss'],
})
export class DefaultMobileItemComponent<T> {
  @Input()
  public selected: T;

  @Input()
  public isMulti: boolean;

  @Input()
  public item: T;
}
