import { animate, group, style, transition, trigger } from "@angular/animations";

export const slideUpAnimation =
  trigger("popup", [
    transition(":enter", [
      group([
        style({ transform: "translateY(100%)" }),
        animate(200, style({ transform: "translateY(0)" })),
      ]),
    ]),
    transition(":leave", [
      group([
        animate(200, style({ transform: "translateY(100%)" })),
      ]),
    ]),
  ]);
