import { Injectable } from '@angular/core';

export interface Confirmation {
  title: string;
  message: string;
  acceptLabel?: string;
  rejectLabel?: string;
  acceptButtonType?: 'primary' | 'plain' | 'plain-icon' | 'primary-outlined';
  rejectButtonType?: string;
}

const defaults: Partial<Confirmation> = {
  acceptLabel: 'OK',
  rejectLabel: 'Cancel',
  acceptButtonType: 'primary',
  rejectButtonType: 'primary-outlined',
};

@Injectable({ providedIn: 'root' })
export class DialogService {
  public confirmation: Confirmation;
  public isAcceptButtonLoading = false;

  private resolve: (value: any) => void;

  public async confirm(confirmation: Confirmation): Promise<boolean> {
    this.confirmation = { ...defaults, ...confirmation };
    this.isAcceptButtonLoading = false;

    const result = await new Promise<boolean>(
      (resolve) => (this.resolve = resolve)
    );
    this.confirmation = undefined;

    return result;
  }

  public async confirmAndDo(
    confirmation: Confirmation,
    action: () => Promise<void>
  ): Promise<boolean> {
    this.confirmation = { ...defaults, ...confirmation };
    this.isAcceptButtonLoading = false;

    const result = await new Promise<boolean>(
      (resolve) => (this.resolve = resolve)
    );

    if (!result) {
      this.confirmation = undefined;
      return;
    }

    this.isAcceptButtonLoading = true;

    await action();

    this.confirmation = undefined;
    this.isAcceptButtonLoading = false;
  }

  public accept() {
    this.resolve && this.resolve(true);
    this.resolve = undefined;
  }

  public reject() {
    this.resolve && this.resolve(false);
    this.resolve = undefined;
  }
}
