import { DOCUMENT } from "@angular/common";
import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { fadeAnimation } from "../../animations/fade.animation";
import { slideUpAnimation } from "../../animations/slide-up.animation";

@Component({
  selector: "vc-slide-up-mobile-dialog",
  templateUrl: "./slide-up-mobile-dialog.component.html",
  styleUrls: ["./slide-up-mobile-dialog.component.scss"],
  animations: [fadeAnimation, slideUpAnimation],
})
export class SlideUpMobileDialogComponent implements OnChanges {

  @Input()
  public heading: string;

  @Input()
  public isOpen = false;

  @Output()
  public closeClick = new EventEmitter<string>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if ("isOpen" in changes) {
      setTimeout(() => {
        this.document.body.classList.toggle("no-scroll", this.isOpen);
      });
    }
  }

}
