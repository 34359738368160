import {
  Component,
  Input,
  forwardRef,
  Optional,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { SelectorBtnItem } from '../../model';

@Component({
  selector: 'vc-selector-button-list',
  templateUrl: './selector-button-list.component.html',
  styleUrls: ['./selector-button-list.component.scss'],
})
export class SelectorButtonListComponent implements ControlValueAccessor {
  @Input() items: SelectorBtnItem[] = [];
  @Input() multi: boolean = false;
  @Input()
  public isInvalid = false;

  constructor(@Optional() private ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  public getIsInvalid(): boolean {
    return (
      this.isInvalid || (this.ngControl?.touched && this.ngControl?.invalid)
    );
  }

  private innerValueMulti: SelectorBtnItem[] = [];
  private innerValueSingle: SelectorBtnItem = null;
  private onChangeCallback: (_: SelectorBtnItem[] | SelectorBtnItem) => void =
    () => {};

  // Write value to the component
  writeValue(value: SelectorBtnItem[] | SelectorBtnItem): void {
    if (this.multi) {
      this.innerValueMulti = value instanceof Array ? value : [value];
    } else {
      this.innerValueSingle = value instanceof Array ? value[0] : value;
    }

    // Preselect items based on the value from the form control
    if (this.multi) {
      this.items.forEach((item) => {
        item.isSelected = this.innerValueMulti.some(
          (selectedItem) => selectedItem.value === item.value
        );
      });
    } else {
      this.items.forEach((item) => {
        item.isSelected =
          this.innerValueSingle && this.innerValueSingle.value === item.value;
      });
    }
  }

  // Register function to call when the component value changes
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // Register function to call when the component is touched (blurred)
  registerOnTouched(fn: any): void {}

  // Set the disabled state of the component
  setDisabledState(isDisabled: boolean): void {
    // Implement if needed
  }

  // Function to handle button click event
  onItemClick(item: SelectorBtnItem) {
    // For single selection
    if (!this.multi) {
      this.items = this.items.map(option => ({
        ...option,
        isSelected: option === item ? !option.isSelected : false
      }));
      this.innerValueSingle = this.items.find(i => i.isSelected) || null;
      this.onChangeCallback(this.innerValueSingle);
    } else {
      // For multi-selection
      this.items = this.items.map(option => ({
        ...option,
        isSelected: option === item ? !option.isSelected : option.isSelected
      }));
      this.innerValueMulti = this.items.filter(i => i.isSelected);
      this.onChangeCallback(this.innerValueMulti);
    }
  }
  private toggleItem(item: SelectorBtnItem) {
    const index = this.innerValueMulti.findIndex(
      (selectedItem) => selectedItem === item
    );
    if (index >= 0) {
      this.innerValueMulti.splice(index, 1);
    } else {
      this.innerValueMulti.push(item);
    }
    this.onChangeCallback(this.innerValueMulti);
  }
}
