<div class="wrapper" [class.isInvalid]="isInvalid">
  <input type="checkbox" [attr.checked]="value || null" (change)="onInputChange($event.target.checked)">

  <div class="uncheck"></div>

  <div class="check">
    <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Stylesheet" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Fields---light-mode" transform="translate(-709.000000, -790.000000)">
          <g id="check_box" transform="translate(709.000000, 790.000000)">
            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
            <path
              d="M19,3 L5,3 C3.89,3 3,3.9 3,5 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.11,21 21,20.1 21,19 L21,5 C21,3.9 20.11,3 19,3 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z"
              id="Shape" fill="#1CC29F" fill-rule="nonzero"></path>
          </g>
        </g>
      </g>
    </svg>
  </div>
</div>
