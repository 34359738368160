import { animate, animateChild, group, query, style, transition, trigger } from "@angular/animations";

export const fadeAnimation =
  trigger("fade", [
    transition("void => *", [
      group([
        query("*", animateChild()),
        style({ opacity: "0" }),
        animate(200, style({ opacity: "1" })),
      ]),
    ]),
    transition("* => void", [
      group([
        query("*", animateChild()),
        animate(200, style({ opacity: "0" })),
      ]),
    ]),
  ]);
