<vc-modal *ngIf="showModal" (modalClosed)="onRejectClicked()">
  <div class="p-3">
    <div class="text-right">
      <vc-button variant="plain-icon" (click)="onRejectClicked()">
        <i class="material-icons">close</i>
      </vc-button>
    </div>
    <div *ngIf="confirmation" class="dialog-overlay mx-2">
      <div class="dialog" (click)="$event.stopPropagation()">
        <div class="mb-3 text-24-heavy title">
          {{ confirmation.title }}
        </div>

        <div class="text-14-light" [innerHTML]="confirmation.message"></div>

        <div class="buttons mx-1 px-2 text-right mt-3">
          <vc-button
            variant="primary"
            class="mr-2"
            [isLoading]="isAcceptButtonLoading"
            (click)="onAcceptClicked()"
          >
            {{ confirmation.acceptLabel }}
          </vc-button>
          <vc-button
            *ngIf="confirmation.rejectButtonType"
            variant="primary-outlined"
            (click)="onRejectClicked()"
          >
            {{ confirmation.rejectLabel }}
          </vc-button>
        </div>
      </div>
    </div>
  </div>
</vc-modal>
