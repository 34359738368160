<div class="side left">
  <i *ngIf="hasBackButton" (click)="backClick.emit()" class="material-icons">arrow_back</i>
</div>

<div class="logo">
</div>

<div class="side right">
  <svg *ngIf="hasMenuIcon" class="hamburger" (click)="menuClick.emit()" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 8V6H21V8H3ZM3 13H21V11H3V13ZM3 18H21V16H3V18Z"/>
  </svg>
</div>
