<table class="verum-table">
  <thead>
    <tr class="header-row">
      <th
        class="text-14 p-2 table-headings {{ column.additionalHeaderClasses }}"
        [style.text-align]="column.align || 'left'"
        *ngFor="let column of columns"
        (click)="onSort(column.name)"
      >
        {{ column.label }}
        <span *ngIf="sortKeys.includes(column.name)" class="sort-icon-wrapper">
          <span class="material-icons sort-icon">
            {{
              sortDirections[column.name] === "ASC"
                ? "arrow_upward_alt"
                : "arrow_downward_alt"
            }}
          </span>
        </span>
      </th>

      <th *ngIf="actionTemplate"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of data" class="table-row">
      <ng-container *ngFor="let column of columns">
        <td
          class="p-2 {{ column.additionalColumnClasses }}"
          *ngIf="!customTemplates[column.name]"
          [style.text-align]="column.align || 'left'"
        >
          {{ row[column.name] }}
        </td>
        <td
          class="p-2"
          *ngIf="customTemplates[column.name]"
          [style.text-align]="column.align || 'left'"
        >
          <ng-container
            [ngTemplateOutlet]="customTemplates[column.name]"
            [ngTemplateOutletContext]="{ $implicit: row[column.customDataField ||column.name] }"
          ></ng-container>
        </td>
      </ng-container>
      <td *ngIf="actionTemplate">
        <div class="action-column">
          <ng-container
            [ngTemplateOutlet]="actionTemplate"
            [ngTemplateOutletContext]="{ $implicit: row }"
          ></ng-container>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<div class="m-3" #spinner>
  <vc-spinner *ngIf="isLoadingData"></vc-spinner>
</div>
