<ng-template #drawer>
  <div class="dropdown-desktop-options">
    <vc-text-input class="dropdown-desktop-options__input" [ngModel]="searchTerm" (ngModelChange)="filterItems($event)" #desktopSearchInput [placeholder]="placeholder">
      <ng-template #suffixTemplate>
        <vc-suffix-icon name="search"></vc-suffix-icon>
      </ng-template>
    </vc-text-input>
    <div class="p-2 cursor-pointer" *ngIf="allowAddItem && searchTerm && filteredItems.length < 1"  (click)="onAddItemClick(searchTerm)">
      Add "{{ searchTerm }}" to list
    </div>
    <div class="dropdown-desktop-options__list">
      <div *ngFor="let item of filteredItems" (click)="onItemClick(item)">
        <div *ngTemplateOutlet="dropItemTemplate, context: { $implicit: item }">
        </div>
      </div>
    </div>
  </div>
</ng-template>
