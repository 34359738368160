import { Injectable } from "@angular/core";
import { GcsApi } from "../../api/gcs.api";
import { Attachment } from "../../interfaces/attachment";

@Injectable()
export class FileInputService {

  constructor(
    private gcsApi: GcsApi,
  ) {
  }

  public upload(file: File, makeSquare: boolean): Promise<Attachment> {
    const formData = new FormData();
    formData.append("file", file, file.name);
    return this.gcsApi.uploadTempFile(formData, makeSquare).toPromise();
  }
}
