import { Component, ContentChild, Input, Optional, TemplateRef } from "@angular/core";
import { NgControl } from "@angular/forms";

@Component({
  selector: "vc-radio",
  templateUrl: "./radio.component.html",
  styleUrls: ["./radio.component.scss"],
})
export class RadioComponent<T> {
  @Input()
  public name: string;

  @Input()
  public value: T;

  @Input()
  public label: string;

  @Input()
  public isInvalid = false;

  @ContentChild("labelTemplate")
  public labelTemplate: TemplateRef<null>;

  constructor(
    @Optional() private ngControl: NgControl,
  ) { }

  public getIsInvalid(): boolean {
    return this.ngControl?.invalid || this.isInvalid;
  }
}
