<div class="default-mobile-item" [class.selected]="item === selected">
  <!-- multi select -->
  <ng-container *ngIf="isMulti; else radioTemplate">
    <label class="display-flex align-items-center">
      <vc-checkbox-input
        [ngModel]="selected.includes(item)"
      ></vc-checkbox-input>
      <div class="label-text" *ngTemplateOutlet="labelTemplate"></div>
    </label>
    <ng-template #labelTemplate>
      <span>
        <ng-content></ng-content>
      </span>
    </ng-template>
  </ng-container>

  <!-- single select -->
  <ng-template #radioTemplate>
    <vc-radio [ngModel]="selected" [value]="item" name="default-mobile-item">
    </vc-radio>
  </ng-template>
</div>
