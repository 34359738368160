import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular-ivy";
import { Replay } from '@sentry/angular-ivy';

import { environment } from "./environments/environment";
import { CoreModule } from "./modules/core/core.module";

Sentry.init({
  dsn: "https://9171369f18224d81a74526bd0caba1a4@o4504338104778752.ingest.sentry.io/4505503950438400",
  environment: environment.production ? "production" : "development",
  integrations: [
    new Sentry.BrowserTracing(),
    new Replay({
      block: ['.blocked-sentry-replay']
    }),
    new Sentry.Integrations.Breadcrumbs({
      console: false
    })
  ],
  // Performance Monitoring
    tracesSampleRate: environment.production ? 0.2 : 1,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(CoreModule)
  .catch(err => console.error(err));
