import { Overlay } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  Optional,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  ValidationErrors,
} from '@angular/forms';
import {
  ControlOnChangeFn,
  ControlOnTouchedFn,
} from '../../interfaces/controls';
import { MediaQueryService } from '../../services/media-query.service';

export type DropdownComponentFilterWithFn<T> = (
  item: T,
  term: string
) => boolean;

@Component({
  selector: 'vc-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent<T> implements ControlValueAccessor {
  @Input()
  public placeholder: string;

  @Input()
  public isMulti: boolean;

  @Input()
  public items: T[];

  @Input() allowAddItem: boolean;

  @Input()
  public heading: string;

  @Input()
  public isInvalid = false;

  @Output() itemAdded: EventEmitter<T> = new EventEmitter();

  @ContentChild('itemDesktopTemplate')
  public itemDesktopTemplate: TemplateRef<null>;

  @ContentChild('itemMobileTemplate')
  public itemMobileTemplate: TemplateRef<null>;

  @ContentChild('valueTemplate')
  public valueTemplate: TemplateRef<null>;

  public isOpen = false;

  public selected: T | T[]; // Allow single or array of selected items

  private onChangeFn: ControlOnChangeFn<T | T[]>;
  private onTouchedFn: ControlOnTouchedFn;

  @Input()
  public filterWith: DropdownComponentFilterWithFn<T> = (item, term) =>
    item.toString().toLowerCase().includes(term.toLowerCase());

  @HostListener('document:click', ['$event'])
  public onDocumentClicked(event: Event): void {
    if (this.isOpen && !this.el.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  constructor(
    public el: ElementRef,
    private overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private mediaQueryService: MediaQueryService,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    @Optional() private ngControl: NgControl
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  public getIsInvalid(): boolean {
    return (
      this.isInvalid || (this.ngControl?.touched && this.ngControl?.invalid)
    );
  }

  public get errors(): ValidationErrors {
    return this.ngControl.errors;
  }

  public onDropdownClicked(): void {
    this.isOpen = true;
  }

  public onItemClick(item: T): void {
    if (this.isMulti) {
      this.toggleOptionMulti(item);
      this.itemAdded.emit(item);
    } else {
      this.selected = item;
      this.onChangeFn && this.onChangeFn(this.selected);
      this.isOpen = false;
    }
  }

  public toggleOptionMulti(item: T): void {
    if (this.isSelectedMulti(item)) {
      this.selected = (this.selected as T[]).filter(
        (selectedItem) => selectedItem !== item
      );
    } else {
      this.selected = [...(this.selected as T[]), item];
    }

    this.onChangeFn && this.onChangeFn(this.selected);
  }

  public isSelectedMulti(item: T): boolean {
    return this.selected && (this.selected as T[]).includes(item);
  }

  public onAddItemClick(item: T): void {
    this.selected = [...(this.selected as T[]), item];
    this.items.push(item);
    this.itemAdded.emit(item);
    this.onChangeFn && this.onChangeFn(this.selected);
    this.isOpen = false;
  }

  public writeValue(value: T | T[]): void {
    this.selected = value;
  }
  

  public registerOnChange(fn: ControlOnChangeFn<T|T[]>): void {
    this.onChangeFn = fn;
  }
  public registerOnTouched(fn: ControlOnTouchedFn): void {
    this.onTouchedFn = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
