<label (drop)="onDrop($event.dataTransfer.files)" vcDragover [class.has-attachments]="hasAttachments">
  <div *ngIf="!isLoading" class="attachment">
    <vc-file-input-preview *ngFor="let attachment of attachments" [attachment]="attachment" [hideRemove]="hideRemove" (removeClick)="onRemoveClicked(attachment)"></vc-file-input-preview>

    <div *ngIf="isHintVisible" class="upload-hint"><p>Drag & drop or<br *ngIf="hideRemove">click to browse</p></div>
  </div>

  <vc-spinner *ngIf="isLoading"></vc-spinner>

  <input type="file" [multiple]="multiple" (change)="onInputChange($event.target)" [accept]="accept"/>
</label>
