import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { TextInputComponent } from "../../text-input/text-input.component";
import { DropdownComponentFilterWithFn } from "../dropdown.component";

@Component({
  selector: "vc-dropdown-mobile-options",
  templateUrl: "./dropdown-mobile-options.component.html",
  styleUrls: ["./dropdown-mobile-options.component.scss"],
})
export class DropdownMobileOptionsComponent<T> implements OnChanges {

  @Input()
  public items: T[];

  @Input() allowAddItem: boolean;

  @Input() isMulti: boolean

  @Input()
  public filterWith: DropdownComponentFilterWithFn<T>;

  @Input()
  public searchTerm: string;

  @Input()
  public placeholder: string;

  @Input()
  public heading: string;

  @Input()
  public isOpen = false;

  @Output()
  public itemClick = new EventEmitter<T>();

  @Output()
  public addItemClick = new EventEmitter<T>();

  @Output()
  public closeClick = new EventEmitter<string>();

  @ContentChild("dropItemTemplate")
  public dropItemTemplate: TemplateRef<null>;

  @ViewChild("mobileSearchInput")
  public mobileSearchInput: TextInputComponent;

  public filteredItems: T[];

  public onItemClick(item: T): void {
    event.stopPropagation();
    this.itemClick.emit(item);
  }

  public onAddItemClick(item: T) {
    this.addItemClick.emit(item);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ("isOpen" in changes) {
      setTimeout(() => {
        this.mobileSearchInput?.setFocus();
      });
    }
    if ("items" in changes || "filterWith" in changes) {
      this.filterItems(null);
    }
  }

  public filterItems(searchTerm: string): void {
    this.searchTerm = searchTerm;
    if (this.items) {
      const filter = searchTerm;

      this.filteredItems = this.filterWith && filter
        ? this.items.filter(e => this.filterWith(e, filter))
        : this.items;
    } else {
      this.filteredItems = this.items;
    }
  }
}
