import { Component, Input, Optional, Output } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { Attachment } from "../../interfaces/attachment";
import { ControlOnChangeFn, ControlOnTouchedFn } from "../../interfaces/controls";
import { FileInputService } from "./file-input.service";

@Component({
  selector: "vc-file-input",
  templateUrl: "./file-input.component.html",
  styleUrls: ["./file-input.component.scss"],
  providers: [FileInputService],
})
export class FileInputComponent implements ControlValueAccessor {

  public get isHintVisible(): boolean { return (this.multiple || !this.hasAttachments) && !this.isLoading; }

  public get hasAttachments(): boolean {
    return !!this.attachments.length;
  }

  constructor(
    @Optional() private ngControl: NgControl,
    private fileInputService: FileInputService,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }
  @Input()
  public multiple = false;

  @Input()
  public accept: string;

  @Input()
  public hideRemove: boolean;

  @Input()
  public makeSquare: boolean;

  public attachments: Attachment[] = [];
  public isLoading = false;

  private onChangeFn: ControlOnChangeFn<Attachment | Attachment[]>;

  public onRemoveClicked(attachment: Attachment): void {
    this.attachments = this.attachments.filter(a => a !== attachment);

    this.onChange();
  }

  public onInputChange(input: HTMLInputElement): void {
    const fileList = input.files;

    this.upload(Array.from(fileList));
    input.value = "";
  }

  public onDrop(fileList: FileList): void {
    this.upload(Array.from(fileList));
  }

  private async upload(files: File[]): Promise<void> {
    this.isLoading = true;

    try {
      const uploads = files.map(f => this.fileInputService.upload(f, this.makeSquare));
      const attachments = await Promise.all(uploads);

      this.attachments = this.multiple
        ? [...(this.attachments || []), ...attachments]
        : [...attachments];

    } catch (e) { console.log("Error in FileInputComponent.upload()", e); }

    this.onChange();
    this.isLoading = false;
  }

  private onChange(): void {
    const value = this.multiple
      ? this.attachments
      : (this.attachments || [])[0];

    this.onChangeFn && this.onChangeFn(value || null);
  }

  public writeValue(obj: Attachment | Attachment[]): void {
    if (this.multiple) {
      this.attachments = Array.isArray(obj) ? obj : [];
    } else {
      const attachment: Attachment = obj as Attachment;
      this.attachments = attachment ? [attachment] : [];
    }
  }
  public registerOnChange(fn: ControlOnChangeFn<Attachment | Attachment[]>): void {
    this.onChangeFn = fn;
  }
  public registerOnTouched(fn: ControlOnTouchedFn): void {
    // throw new Error("Method not implemented.");
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
