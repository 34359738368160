import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

const complexityRegexes = [
  /^((?=.*[a-z])(?=.*[0-9]))(?=.{8,})/,
  /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))(?=.{8,})/,
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\£\$%\^&\*\-_=+])(?=.{8,})/,
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\£\$%\^&\*\-_=+])(?=.{12,})/,
];

const complexityWording = [
  "Weak",
  "Average",
  "Good",
  "Strong",
];

@Component({
  selector: "vc-password-score",
  templateUrl: "./password-score.component.html",
  styleUrls: ["./password-score.component.scss"],
})
export class PasswordScoreComponent implements OnChanges {
  @Input()
  public password: string;

  public score = 0;
  public wording = "";

  constructor() { }

  public ngOnChanges(changes: SimpleChanges): void {
    if ("password" in changes) {
      const password = changes.password.currentValue;

      this.calculateScore(password);
    }
  }

  private calculateScore(password: string): void {
    this.score = 0;

    for (let i = 0; i < 4; i++) {
      const regexp = complexityRegexes[i];

      if (regexp.test(password)) {
        this.score = i + 1;
      }
    }

    this.wording = complexityWording[this.score - 1];
  }
}
