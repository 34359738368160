import { Component, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "vc-debug-form",
  templateUrl: "./debug-form.component.html",
  styleUrls: ["./debug-form.component.scss"],
})
export class DebugFormComponent {
  @Input()
  public form: UntypedFormGroup;
}
