import { inject, Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn, UrlTree } from "@angular/router";
import { Observable, catchError, of } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
  })
  class PermissionsService {
  
    constructor(
        private router: Router,
        private authService: AuthService
    ) {}
  
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isAuthenticated().pipe(
      catchError(err => {
        // TODO toast err
        this.navigateToLoginWithRedirect(state);
        return of(false);
      }),
    );
  }

  private navigateToLoginWithRedirect(state: RouterStateSnapshot): void {
    this.router.navigate(["/auth/login"], { queryParams: { redirectTo: state.url }}).then();
  }
  }
  
  export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
    return inject(PermissionsService).canActivate(next, state);
  }