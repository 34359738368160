import { Component, Input } from "@angular/core";

@Component({
  selector: "vc-suffix-icon",
  templateUrl: "./suffix-icon.component.html",
  styleUrls: ["./suffix-icon.component.scss"],
})
export class SuffixIconComponent {

  @Input()
  public name: "keyboard_arrow_down" | "search";

}
