import { Component, OnInit } from "@angular/core";

@Component({
  selector: "vc-form-field",
  templateUrl: "./form-field.component.html",
  styleUrls: ["./form-field.component.scss"],
})
export class FormFieldComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
