import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

type SsoProviderType = "google" | "apple" | "microsoft";

@Injectable({ providedIn: "root" })
export class SsoService {

  public redirectToGoogle(): void {
    this.redirectToSSOProvider("google");
  }

  public redirectToApple(): void {
    this.redirectToSSOProvider("apple");
  }

  public redirectToMicrosoft(): void {
    this.redirectToSSOProvider("microsoft");
  }

  private redirectToSSOProvider(ssoProvider: SsoProviderType): void {
    window.open(`${environment.apiUrl}/oauth2/authorization/${ssoProvider}`, "_self");
  }
}
