import { animate, group, style, transition, trigger } from "@angular/animations";

export const menuAnimations = [
  trigger("overlay", [
    transition(":enter", [
      group([
        style({ opacity: "0"}),
        animate(200, style({ opacity: "0.3"})),
      ])])]),
  trigger("isMobileMenuOpen", [
    transition("false => true", [
      group([
        style({ left: "-336px", opacity: "0"}),
        animate(200, style({ left: "0", opacity: "1"})),
      ])])]),
  trigger("isItemOpen", [
    transition("* => true", [
      group([
        style({ "max-height": "0px", opacity: "0"}),
        animate(200, style({ "max-height": "200px", opacity: "1"})),
      ])])]),
];
