import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { environment } from "../../environments/environment";
import { AuthGuard } from "../shared/services/permissions.service";

const coreRoutes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("../auth/auth.module").then(m => m.AuthModule),
  },
  {
    path: "dashboard",
    canActivate: [AuthGuard],
    loadChildren: () => import("../dashboard/dashboard.module").then(m => m.DashboardModule),
  },
  {
    path: "account",
    canActivate: [AuthGuard],
    loadChildren: () => import("../account/account.module").then(m => m.AccountModule),
  },
  {
    path: "reports",
    loadChildren: () => import("../reports/reports.module").then(m => m.ReportsModule),
  },
  {
    path: "setup",
    canActivate: [AuthGuard],
    loadChildren: () => import("../setup/setup.module").then(m => m.SetupModule),
  },
];

const debugRoutes: Routes = [
  {
    path: "debug",
    loadChildren: () => import("../debug/debug.module").then(m => m.DebugModule),
  },
];

const routes = [
  ...coreRoutes,
  ...(environment.production ? [] : debugRoutes),
  {
    path: "**",
    redirectTo: "auth",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule { }
