<div class="setup-side-nav">
  <div class="text-16-heavy title">{{ title }}</div>
  <ng-container *ngFor="let item of menuItems">
    <a
      [routerLink]="item.url"
      routerLinkActive="active"
      class="setup-menu-item display-flex align-items-center"
    >
      <img [src]="item.iconSrc" />
      <span class="text-16-light-gray1 label-text"> {{ item.label }}</span>
    </a>
  </ng-container>
</div>
