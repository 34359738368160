import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxInputComponent } from './components/checkbox/checkbox-input/checkbox-input.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { DebugFormComponent } from './components/debug-form/debug-form.component';
import { DefaultDesktopItemComponent } from './components/dropdown/dropdown-desktop-options/default-desktop-item/default-desktop-item.component';
import { DropdownDesktopOptionsComponent } from './components/dropdown/dropdown-desktop-options/dropdown-desktop-options.component';
import { DefaultMobileItemComponent } from './components/dropdown/dropdown-mobile-options/default-mobile-item/default-mobile-item.component';
import { DropdownMobileOptionsComponent } from './components/dropdown/dropdown-mobile-options/dropdown-mobile-options.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { FileInputPreviewComponent } from './components/file-input/file-input-preview/file-input-preview.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { LayoutWithMenuComponent } from './components/layout-with-menu/layout-with-menu.component';
import { LinkButtonComponent } from './components/link-button/link-button.component';
import { LinkSwitchComponent } from './components/link-switch/link-switch.component';
import { MenuComponent } from './components/menu/menu.component';
import { PageHeaderLogoComponent } from './components/page-header-logo/page-header-logo.component';
import { PasswordScoreComponent } from './components/password-score/password-score.component';
import { PhoneInputWrapperComponent } from './components/phone-input-wrapper/phone-input-wrapper.component';
import { PhonePrefixDropdownComponent } from './components/phone-prefix-dropdown/phone-prefix-dropdown.component';
import { ProfilePictureComponent } from './components/profile-picture/profile-picture.component';
import { RadioInputComponent } from './components/radio/radio-input/radio-input.component';
import { RadioComponent } from './components/radio/radio.component';
import { SlideUpMobileDialogComponent } from './components/slide-up-mobile-dialog/slide-up-mobile-dialog.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SuffixIconComponent } from './components/suffix-icon/suffix-icon.component';
import { TextInputComponent } from './components/text-input/text-input.component';
import { ToastComponent } from './components/toast/toast.component';
import { DragoverDirective } from './directives/dragover.directive';
import { IfMediaDirective } from './directives/if-media.directive';
import { SrcsetDirective } from './directives/srcset.directive';
import { MediaQueryService } from './services/media-query.service';
import {
  BottomSheetComponent,
  ConfirmDialogComponent,
  HeaderBarComponent,
  ListPlaceholderComponent,
  ModalComponent,
  SelectorButtonListComponent,
  SideMenuComponent,
  TableComponent,
} from './components';

const exports = [
  ButtonComponent,
  CheckboxComponent,
  CheckboxInputComponent,
  ColorPickerComponent,
  DefaultDesktopItemComponent,
  DefaultMobileItemComponent,
  DragoverDirective,
  DropdownComponent,
  DropdownDesktopOptionsComponent,
  DropdownMobileOptionsComponent,
  FileInputComponent,
  FileInputPreviewComponent,
  IfMediaDirective,
  LinkButtonComponent,
  PhoneInputWrapperComponent,
  PhonePrefixDropdownComponent,
  ProfilePictureComponent,
  RadioComponent,
  RadioInputComponent,
  SlideUpMobileDialogComponent,
  SpinnerComponent,
  SrcsetDirective,
  SuffixIconComponent,
  TextInputComponent,
  FormFieldComponent,
  PageHeaderLogoComponent,
  LinkSwitchComponent,
  DebugFormComponent,
  PasswordScoreComponent,
  MenuComponent,
  LayoutWithMenuComponent,
  ToastComponent,
  SideMenuComponent,
  HeaderBarComponent,
  ListPlaceholderComponent,
  SelectorButtonListComponent,
  TableComponent,
  BottomSheetComponent,
  ModalComponent,
  ConfirmDialogComponent
];

@NgModule({
  imports: [CommonModule, FormsModule, OverlayModule, RouterModule],
  declarations: exports,
  exports,
  providers: [MediaQueryService],
})
export class SharedModule {}
