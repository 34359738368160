<!-- Form Control -->
<div class="dropdown" (click)="onDropdownClicked()" (focus)="onDropdownClicked()" [class.isInvalid]="getIsInvalid()">
  <div *ngTemplateOutlet="valueTemplate || defaultValueTemplate, context: { $implicit: selected }"></div>
  &nbsp;
  <vc-suffix-icon name="keyboard_arrow_down"></vc-suffix-icon>
</div>

<!-- Desktop Options -->
<ng-container *vcIfMedia="'desktop'">
  <vc-dropdown-desktop-options *ngIf="isOpen"
                               [items]="items"
                               [filterWith]="filterWith"
                               [placeholder]="placeholder"
                               [parentElementRef]="el"
                               [parentViewContainerRef]="viewContainerRef"
                               [allowAddItem]="allowAddItem"
                               (itemClick)="onItemClick($event)"
                               (addItemClick)="onAddItemClick($event)"
                               >
    <ng-template #dropItemTemplate let-item>
      <div *ngTemplateOutlet="itemDesktopTemplate || defaultDesktopItemTemplate, context: { $implicit: item }"></div>
    </ng-template>
  </vc-dropdown-desktop-options>
</ng-container>

<!-- Mobile Options -->
<ng-container *vcIfMedia="'phone'">
  <vc-dropdown-mobile-options [items]="items"
                              [filterWith]="filterWith"
                              [placeholder]="placeholder"
                              [heading]="heading"
                              [isOpen]="isOpen"
                              [allowAddItem]="allowAddItem"
                              (itemClick)="onItemClick($event)"
                              (addItemClick)="onAddItemClick($event)"
                              (closeClick)="isOpen = false">
    <ng-template #dropItemTemplate let-item>
      <div *ngTemplateOutlet="itemMobileTemplate || defaultMobileItemTemplate, context: { $implicit: item }"></div>
    </ng-template>
  </vc-dropdown-mobile-options>
</ng-container>

<ng-template #defaultValueTemplate let-item>
  {{item}}
</ng-template>

<ng-template #defaultDesktopItemTemplate let-item>
  <vc-default-desktop-item>
    {{item}}
  </vc-default-desktop-item>
</ng-template>

<ng-template #defaultMobileItemTemplate let-item>
  <vc-default-mobile-item [item]="item" [selected]="selected" [isMulti]="isMulti">
    {{item}}
  </vc-default-mobile-item>
</ng-template>
