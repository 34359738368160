import { Component, ContentChild, ElementRef, HostListener, Input, Optional, TemplateRef } from "@angular/core";
import { ControlValueAccessor, NgControl, ValidationErrors } from "@angular/forms";

@Component({
  selector: "vc-text-input",
  templateUrl: "./text-input.component.html",
  styleUrls: ["./text-input.component.scss"],
})
export class TextInputComponent implements ControlValueAccessor {

  constructor(
    @Optional() private ngControl: NgControl,
    private el: ElementRef,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }
  @Input()
  public type: "text" | "password" | "tel" | "number" = "text";

  @Input()
  public placeholder: string;

  @Input()
  public isInvalid = false;

  @Input()
  public maxlength: number;

  @Input()
  public uppercase: boolean;

  @Input()
  public readonly: boolean;

  @ContentChild("suffixTemplate")
  public suffixTemplate?: TemplateRef<null>;

  @ContentChild("prefixTemplate")
  public prefixTemplate?: TemplateRef<null>;

  public value: string;
  public showPassword = false;
  public wasPassword = undefined;

  private onChane: (value: string) => void;
  private onTouchedFn: () => void;

  @HostListener("click")
  public setFocus(): void {
    this.el.nativeElement.querySelector("input").focus();
  }

  public getIsInvalid(): boolean {
    return (this.ngControl?.invalid && this.ngControl?.dirty) || this.isInvalid;
  }

  public get errors(): ValidationErrors {
    return this.ngControl.errors;
  }

  public onInput(value: string): void {
    this.value = value;

    this.onChane && this.onChane(value);
  }

  public onBlur(): void {
    this.onTouchedFn && this.onTouchedFn();
  }

  public onEyeClicked(): void {
    this.showPassword = !this.showPassword;
    this.wasPassword = true;

    this.type = this.showPassword
      ? "text"
      : "password";
  }

  public writeValue(obj: string): void {
    this.value = obj;
  }
  public registerOnChange(fn: (value: string) => void): void {
    this.onChane = fn;
  }
  public registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
