import { Component, EventEmitter, Input, Output } from '@angular/core';

type modalSize = 'modal-sm' | 'modal-md' | 'modal-lg';
@Component({
  selector: 'vc-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() size: modalSize = 'modal-sm';
  @Output() modalClosed = new EventEmitter<void>();

  closeModal(): void {
    this.modalClosed.emit();
  }
}
