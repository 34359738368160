import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vc-list-placeholder',
  templateUrl: './list-placeholder.component.html',
  styleUrls: ['./list-placeholder.component.scss']
})
export class ListPlaceholderComponent {
  @Input() imgSrc: string;
  @Input() description: string;
  @Input() actionBtnText: string;

  @Output()
  public btnClicked = new EventEmitter<boolean>();
  
  onBtnClicked() {
    this.btnClicked.emit(true);
  }
}
