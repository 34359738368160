<vc-text-input [(ngModel)]="value" (ngModelChange)="onModelChange()" maxlength="7" [uppercase]="true" [isInvalid]="getIsInvalid()"></vc-text-input>

<label class="postfix">
  <div class="dot" [style.background]="getValidValue()"></div>

  <vc-link-button>Select</vc-link-button>

  <input type="color" [value]="value" (change)="onInputChange($event.target.value)">
</label>

