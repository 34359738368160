import { Injectable } from "@angular/core";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";

export interface RegisterControlOptions {
  preventAutofocus?: boolean;
}

type ControlsTuple = [HTMLElement, AbstractControl, RegisterControlOptions];

@Injectable({ providedIn: "root" })
export class FormFieldService {
  private controls: ControlsTuple[] = [];

  public registerControl(element: HTMLElement, control: AbstractControl, options?: RegisterControlOptions): void {
    this.controls.push([element, control, options]);
  }

  public checkFormValidity(form: UntypedFormGroup): boolean {
    this.updateValueAndValidityFormGroup(form);
    form.updateValueAndValidity();

    if (form.invalid) {
      this.scrollAndFocusInvalidControl();
    }

    return form.valid;
  }

  public scrollAndFocusInvalidControl(): void {
    for (const [element, control, options] of this.getControlsSortedByPosition()) {
      if (control.invalid) {
        const inputElement = element.querySelector("input") || element.querySelector("textarea");

        inputElement && !options?.preventAutofocus && inputElement.focus();
        element.scrollIntoView({ behavior: "smooth" });

        return;
      }
    }
  }

  private getControlsSortedByPosition(): ControlsTuple[] {
    return this.controls.sort(([element1], [element2]) => {
      const element1top = element1.getBoundingClientRect().top;
      const element2top = element2.getBoundingClientRect().top;

      return element1top - element2top;
    });
  }

  private updateValueAndValidityFormGroup(formGroup: UntypedFormGroup): void {
    Object.entries(formGroup.controls).forEach(([_, control]) => {
      if (control instanceof UntypedFormGroup) {
        this.updateValueAndValidityFormGroup(control);
      }

      control.enabled && control.markAsDirty({ onlySelf: true });
      control.enabled && control.markAsTouched({ onlySelf: true });
      control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    });
  }
}
