import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "vc-page-header-logo",
  templateUrl: "./page-header-logo.component.html",
  styleUrls: ["./page-header-logo.component.scss"],
})
export class PageHeaderLogoComponent {
  @Input()
  public hasBackButton = false;

  @Input()
  public hasMenuIcon = false;

  @Output()
  public backClick = new EventEmitter();

  @Output()
  public menuClick = new EventEmitter();
}
