import { Injectable } from "@angular/core";
import { Subject } from "rxjs";


export type ToastSeverity = "info" | "error";
export type ToastMessage = string;

interface Error {
  error?: { error?: { message?: string } };
  message?: string;
}

@Injectable({ providedIn: "root"})
export class ToastService {
  private _message$ = new Subject<[ToastMessage, ToastSeverity]>();

  public message$ = this._message$.asObservable();

  public add(message: ToastMessage, severity: ToastSeverity = "info", error?: Error): void {
    this._message$.next([error ? this.formatMessageWithError(message, error) : message, severity]);
  }

  private formatMessageWithError(message: string, error: Error): string {
    return `${message}: ${error.error?.error?.message || error.message || error.toString()}`;
  }
}
