import { Component, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Component({
  selector: 'vc-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
})
export class BottomSheetComponent {

  @Output() sheetClosed: EventEmitter<boolean> = new EventEmitter();
  
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    // Check if the clicked element is outside the bottom-sheet-content
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.closeBottomSheet();
    }
  }

  constructor(private elementRef: ElementRef) {}

  closeBottomSheet() {
    // Add logic here to close the bottom sheet
    this.sheetClosed.emit();

  }
  onContentClick(event: MouseEvent) {
    // Prevent the click event from propagating to the parent element
    event.stopPropagation();
  }
}
