import { Component, OnInit, Optional } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { Attachment } from "../../interfaces/attachment";
import { ControlOnChangeFn, ControlOnTouchedFn } from "../../interfaces/controls";

@Component({
  selector: "vc-profile-picture",
  templateUrl: "./profile-picture.component.html",
  styleUrls: ["./profile-picture.component.scss"],
})
export class ProfilePictureComponent implements ControlValueAccessor {

  private onChangeFn: ControlOnChangeFn<Attachment>;
  private onTouchedFn: ControlOnTouchedFn;
  public profilePicture: Attachment;

  constructor(
    @Optional() private ngControl: NgControl,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  public writeValue(value: Attachment): void {
    if (value) {
      this.profilePicture = value;
    }
  }
  public registerOnChange(fn: ControlOnChangeFn<Attachment>): void {
    this.onChangeFn = fn;
  }
  public registerOnTouched(fn: ControlOnTouchedFn): void {
    this.onTouchedFn = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
  public getIsInvalid(): boolean {
    return this.ngControl?.touched && this.ngControl?.invalid;
  }
}
