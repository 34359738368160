import {
  ConnectedPosition,
  Overlay,
  OverlayRef,
  PositionStrategy,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TextInputComponent } from '../../text-input/text-input.component';
import { DropdownComponentFilterWithFn } from '../dropdown.component';

@Component({
  selector: 'vc-dropdown-desktop-options',
  templateUrl: './dropdown-desktop-options.component.html',
  styleUrls: ['./dropdown-desktop-options.component.scss'],
})
export class DropdownDesktopOptionsComponent<T>
  implements AfterViewInit, OnDestroy, OnChanges
{
  @Input()
  public items: T[];

  @Input() allowAddItem: boolean;

  @Input()
  public filterWith: DropdownComponentFilterWithFn<T>;

  public searchTerm: string;

  @Input()
  public placeholder: string;

  @Input()
  public parentViewContainerRef: ViewContainerRef;

  @Input()
  public parentElementRef: ElementRef;

  @Output()
  public itemClick = new EventEmitter<T>();

  @Output()
  public addItemClick = new EventEmitter<T>();

  @ContentChild('dropItemTemplate')
  public dropItemTemplate: TemplateRef<null>;

  @ViewChild('desktopSearchInput')
  public desktopSearchInput: TextInputComponent;

  @ViewChild('drawer')
  public drawer: TemplateRef<null>;

  public filteredItems: T[];

  private overlayReference: OverlayRef;

  private destroy$ = new Subject<boolean>();

  constructor(private overlay: Overlay) {}

  public filterItems(searchTerm: string): void {
    this.searchTerm = searchTerm;
    if (this.items) {
      const filter = searchTerm;

      this.filteredItems =
        this.filterWith && filter
          ? this.items.filter((e) => this.filterWith(e, filter))
          : this.items;
    } else {
      this.filteredItems = this.items;
    }
  }

  public onItemClick(item: T): void {
    this.itemClick.emit(item);
    this.closeDrawer();
  }

  public onAddItemClick(item: T) {
    this.addItemClick.emit(item);
    this.closeDrawer();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('items' in changes || 'filterWith' in changes) {
      this.filterItems(null);
    }
  }

  public ngAfterViewInit(): void {
    this.openDrawer();
    window.setTimeout(() => {
      this.desktopSearchInput?.setFocus();
    });
  }

  // DRAWER

  protected closeDrawer(): void {
    if (this.drawer) {
      this.overlayRef?.detach();
    }
  }

  protected openDrawer(): void {
    const t = new TemplatePortal(this.drawer, this.parentViewContainerRef);
    this.overlayRef?.attach(t);
  }

  protected get overlayRef(): OverlayRef {
    if (this.overlayReference) {
      return this.overlayReference;
    }
    this.overlayReference = this.createOverlayRef();
    return this.overlayReference;
  }

  protected createOverlayRef(): OverlayRef {
    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    const hasBackdrop = true;
    const backdropClass = 'cdk-overlay-transparent-backdrop';
    const positionStrategy = this.getDesktopPositionStrategy();

    const overlayReference = this.overlay.create({
      positionStrategy,
      scrollStrategy,
      hasBackdrop,
      backdropClass,
    });

    overlayReference
      .backdropClick()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.closeDrawer());

    return overlayReference;
  }

  private getDesktopPositionStrategy(): PositionStrategy {
    // above or under input on desktop
    const positions: ConnectedPosition[] = [
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
        offsetY: 0,
      },
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetY: 0,
      },
    ];

    return this.overlay
      .position()
      .flexibleConnectedTo(this.parentElementRef)
      .withViewportMargin(20)
      .withPositions(positions);
  }

  public ngOnDestroy(): void {
    this.closeDrawer();
    this.destroy$.next(true);
  }
}
