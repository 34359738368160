import { Component, Input, Optional } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { ControlOnChangeFn, ControlOnTouchedFn } from "../../../interfaces/controls";

@Component({
  selector: "vc-radio-input",
  templateUrl: "./radio-input.component.html",
  styleUrls: ["./radio-input.component.scss"],
})
export class RadioInputComponent<T> implements ControlValueAccessor {

  constructor(
    @Optional() private ngControl: NgControl,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }
  @Input()
  public name: string;

  @Input()
  public value: T;

  @Input()
  public isInvalid: boolean;

  public groupValue: T;

  private onChane: ControlOnChangeFn<T>;
  private onTouchedFn: ControlOnTouchedFn;

  public getIsChecked(): boolean {
    const checked = !!this.groupValue && this.groupValue === this.value;

    return checked || null;
  }

  public getIsInvalid(): boolean {
    return this.ngControl?.invalid || this.isInvalid;
  }

  public onInputChange(checked: boolean): void {
    this.onChane && this.onChane(this.value);
  }

  public writeValue(groupValue: T): void {
    this.groupValue = groupValue;
  }
  public registerOnChange(fn: ControlOnChangeFn<T>): void {
    this.onChane = fn;
  }
  public registerOnTouched(fn: ControlOnTouchedFn): void {
    this.onTouchedFn = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }
}
