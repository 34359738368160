export interface MenuItem {
  name: string;
  description: string;
  url?: string;
  icon?: string;
  isAccount?: boolean;
  isOpen?: boolean;
  children?: MenuItem[];
}

export const menuItems: MenuItem[] = [
  {
    name: "Dashboard",
    description: "",
    url: "/dashboard",
    icon: "/assets/images/menu/dashboard.svg",
  },
  {
    name: "Reports",
    description: "",
    url: "/reports",
    icon: "/assets/images/menu/pie_chart.svg",
  },
  {
    name: "Setup",
    description: "",
    url: "/setup",
    icon: "/assets/images/menu/settings.svg",
    children: [
      {
        name: "KPIs",
        description: "Add, edit & delete KPIs",
        url: "/setup/kpis",
        icon: "/assets/images/menu/kpi.svg",
      },
      {
        name: "Receptors",
        description: "Add, edit & delete receptors",
        url: "/setup/receptors",
        icon: "/assets/images/menu/receptors.svg",
      },
      // {
      //   name: "Events",
      //   description: "Add, edit & delete events",
      //   url: "/setup/events",
      //   icon: "/assets/images/menu/Events.svg",
      // },
      // {
      //   name: "Objectives",
      //   description: "Manage short and long objectives",
      //   url: "/setup/objectives",
      //   icon: "/assets/images/menu/Objectives.svg",
      // },
      // {
      //   name: "Actions",
      //   description: "Add, edit & delete actions",
      //   url: "/setup/actions",
      //   icon: "/assets/images/menu/Actions.svg",
      // },
      // {
      //   name: "Vitals",
      //   description: "Manage vitals integrations",
      //   url: "/setup/vitals",
      //   icon: "/assets/images/menu/Vitals.svg",
      // },
    ],
  },
  {
    name: "Account",
    description: "",
    url: "/account",
    icon: "/assets/images/menu/face.svg",
    isAccount: true,
    children: [
      {
        name: "Personal profile",
        description: "Edit personal profile",
        url: "/account/personal",
        icon: "/assets/images/menu/face.svg",
      },
      {
        name: "Security",
        description: "Update to security details ",
        url: "/account/security",
        icon: "/assets/images/menu/admin_panel_settings.svg",
      },
      {
        name: "Company profile",
        description: "Company details & branding",
        url: "/account/company-profile",
        icon: "/assets/images/menu/business.svg",
      },
      {
        name: "Subscription",
        description: "View and update pacakge",
        url: "/account/subscription",
        icon: "/assets/images/menu/loyalty-24px.svg",
      },
      {
        name: "User management",
        description: "Add & edit users",
        url: "/account/users",
        icon: "/assets/images/menu/group.svg",
      },
      {
        name: "Payments",
        description: "View payment cards & history",
        url: "/account/users",
        icon: "/assets/images/menu/payment.svg",
      },
    ],
  },
];

