import { Component, ContentChild } from "@angular/core";
import { ValidationErrors } from "@angular/forms";
import { PhonePrefixDropdownComponent } from "../phone-prefix-dropdown/phone-prefix-dropdown.component";
import { TextInputComponent } from "../text-input/text-input.component";

@Component({
  selector: "vc-phone-input-wrapper",
  templateUrl: "./phone-input-wrapper.component.html",
  styleUrls: ["./phone-input-wrapper.component.scss"],
})
export class PhoneInputWrapperComponent<T> {

  @ContentChild(PhonePrefixDropdownComponent)
  public phonePrefix!: PhonePrefixDropdownComponent;

  @ContentChild(TextInputComponent)
  public phone!: TextInputComponent;

  constructor() { }

  public getIsInvalid(): boolean {
    if (!this.phone || !this.phonePrefix) {
      return false;
    }

    return this.phone.getIsInvalid() || this.phonePrefix.getIsInvalid();
  }

  public getErrors(): ValidationErrors {
    if (!this.phone || !this.phonePrefix) {
      return null;
    }

    return this.phonePrefix.errors ? this.phonePrefix.errors : this.phone.errors;
  }
}
