import { Component, ContentChild, HostBinding, Input, TemplateRef } from "@angular/core";

@Component({
  selector: "vc-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent {
  @Input()
  public disabled = false;

  @Input()
  public variant: "primary" | "plain" | "plain-icon" | "primary-outlined";

  @Input()
  @HostBinding("class.isLoading")
  public isLoading = false;

  @ContentChild("prefixTemplate")
  public prefixTemplate?: TemplateRef<null>;
}
