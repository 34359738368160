import { Component, Optional } from "@angular/core";
import { AbstractControl, ControlValueAccessor, NgControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { ControlOnChangeFn, ControlOnTouchedFn } from "../../interfaces/controls";

function isValidColor(color: string): boolean {
  if (typeof color !== "string") {
    return false;
  }

  const lowercase = color.toLowerCase();
  const valid = !!lowercase.match(/^#([0-9a-f]{6})$/i);

  return valid;
}

export const colorValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value;

  return isValidColor(value)
    ? null
    : { color: true };
};

@Component({
  selector: "vc-color-picker",
  templateUrl: "./color-picker.component.html",
  styleUrls: ["./color-picker.component.scss"],
})
export class ColorPickerComponent implements ControlValueAccessor {

  constructor(
    @Optional() private ngControl: NgControl,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }
  public value: string;

  private onChane: ControlOnChangeFn<string>;
  private onTouchedFn: ControlOnTouchedFn;

  public getValidValue(): string {
    return isValidColor(this.value)
      ? this.value
      : "";
  }

  public getIsInvalid(): boolean {
    return this.ngControl?.invalid && this.ngControl?.dirty;
  }

  public onModelChange(): void {
    if (this.value && this.value.trim()[0] !== "#") {
      this.value = `#${this.value.trim()}`.substring(0, 7);
    }

    this.onChane && this.onChane(this.value);
  }

  public onInputChange(value: string): void {
    this.value = value;

    this.onChane && this.onChane(this.value);
  }

  public writeValue(value: string): void {
    this.value = value;
  }
  public registerOnChange(fn: ControlOnChangeFn<string>): void {
    this.onChane = fn;
  }
  public registerOnTouched(fn: ControlOnTouchedFn): void {
    this.onTouchedFn = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }
}
