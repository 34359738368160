<div class="display-flex selector-btn-list-wrapper justify-content-space-between">
  <div
    class="align-items-center display-flex justify-content-center selector-box"
    [ngClass]="{'selected': item.isSelected}"
    (click)="onItemClick(item)"
    [class.isInvalid]="getIsInvalid()"
    *ngFor="let item of items"
  >
    <img [src]="item.iconUrl" [alt]="item.label" />
    <span class="mt-1">{{ item.label }}</span>
  </div>
</div>
