import { Component, EventEmitter, Input, Output } from "@angular/core";
import { GcsApi } from "../../../api/gcs.api";
import { Attachment } from "../../../interfaces/attachment";

@Component({
  selector: "vc-file-input-preview",
  templateUrl: "./file-input-preview.component.html",
  styleUrls: ["./file-input-preview.component.scss"],
})
export class FileInputPreviewComponent {
  @Input()
  public attachment: Attachment;
  @Input()
  public hideRemove: boolean;

  @Output()
  public removeClick = new EventEmitter<boolean>();

  constructor(
    private gcsApi: GcsApi,
  ) {
  }

  public onRemoveClicked(event: Event): void {
    event.preventDefault();
    this.removeClick.next(true);
  }

  public getThumbnailUrl(): string {
    if (!this.attachment) {
      return undefined;
    }

    const parts = [
      this.attachment.folder,
      this.attachment.thumbnail,
    ];

    return parts.join("/");
  }

  public get src(): string {
    const filename = this.attachment.thumbnail || this.attachment.filename;
    return this.gcsApi.downloadTempFileUrl(this.attachment.folder, filename);
  }
}
