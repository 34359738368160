import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "projects/verum-client/src/environments/environment";
import { AuthService } from "../../services";
import { MenuItem, menuItems } from "./menu-items";
import { menuAnimations } from "./menu.animation";

@Component({
  selector: "vc-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
  animations: menuAnimations,
})
export class MenuComponent {
  public items: MenuItem[] = menuItems;
  public currentUser$ = this.authService.currentUser$;
  public currentCompany$ = this.authService.currentCompany$;

  @Input()
  @HostBinding("class.isMobileMenuOpen")
  public isMobileMenuOpen = false;

  @HostListener("document:click")
  public onDocumentClicked(): void {
    this.items.forEach(i => i.isOpen = false);
  }

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  public getUrl(parts: string[]) {
    const full = [
      environment.apiUrl,
      ...parts,
    ].join("/");

    return full;
  }

  public onItemClicked(item: MenuItem): void {
    const isOpen = item.isOpen;
    this.items.forEach(i => i.isOpen = false);

    if (item.children) {
      item.isOpen = !isOpen;
    } else {
      this.router.navigate([item.url]);
    }
  }

  public isItemActive(item: MenuItem): boolean {
    const url = this.router.routerState.snapshot.url;

    return url.startsWith(item.url);
  }
}
