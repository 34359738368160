import { Component, Input, Optional } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { ControlOnChangeFn, ControlOnTouchedFn } from "../../interfaces/controls";

@Component({
  selector: "vc-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() public label: string;

  @Input()
  public isInvalid = false;

  public value: boolean;

  constructor(
    @Optional() private ngControl: NgControl,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  private onChane: ControlOnChangeFn<boolean>;
  private onTouchedFn: ControlOnTouchedFn;

  public onValueChange(): void {
    this.onChane && this.onChane(this.value);
  }

  public getIsInvalid(): boolean {
    return (this.ngControl?.invalid && this.ngControl?.dirty) || this.isInvalid;
  }

  public writeValue(value: boolean): void {
    this.value = value;
  }
  public registerOnChange(fn: ControlOnChangeFn<boolean>): void {
    this.onChane = fn;
  }
  public registerOnTouched(fn: ControlOnTouchedFn): void {
    this.onTouchedFn = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }
}
