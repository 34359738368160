import { Component, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { delay, takeUntil, tap } from "rxjs/operators";

import { fadeAnimation } from "../../animations/fade.animation";
import { ToastMessage, ToastService, ToastSeverity } from "../../services/toast.service";

const TOAST_LIFETIME = 3000;

@Component({
  selector: "vc-toast",
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.scss"],
  animations: [fadeAnimation],
})
export class ToastComponent implements OnDestroy {
  public severity: ToastSeverity;
  public message: ToastMessage;

  private destroy$ = new Subject();

  constructor(private toastService: ToastService) {
    this.toastService.message$.pipe(
      tap(() => console.log("toast toast")),
      takeUntil(this.destroy$),
      tap(([message, severity]) => { this.message = message; this.severity = severity; }),
      delay(TOAST_LIFETIME),
      tap(() => this.message = undefined),
    ).subscribe();
  }

  public onClose(): void {
    this.message = undefined;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
