<div *ngIf="isMobileMenuOpen" class="overlay" (click)="isMobileMenuOpen = false" [@overlay]="isMobileMenuOpen"></div>

<nav [@isMobileMenuOpen]="isMobileMenuOpen">
  <div width="26" height="22" class="verum-logo"></div>

  <div class="separator"></div>

  <ul class="parents">
    <li *ngFor="let item of items" [class.account-item]="item.isAccount" [class.isOpen]="item.isOpen">
      <a (click)="onItemClicked(item); $event.stopPropagation()" [class.active]="isItemActive(item)">
        <div class="icon">
          <img [src]="item.icon">
        </div>

        <div class="item-label">
          {{ item.name }}
        </div>

        <div *ngIf="item.isAccount" class="account-box">
          <ng-container *ngIf="currentCompany$ | async as company">
            <!-- <img width="64" height="32" [src]="getUrl([company.logo.folder, '/', company.logo.filename])" [vcSrcset]="getUrl([company.logo.folder, '/', company.logo.thumbnail])" class="logo"> -->

            <div class="dropdown">
              <div *ngIf="currentUser$ | async as user">
                <div class="name">{{ user.firstName }} {{ user.lastName }}</div>
                <div class="company">{{ company.name }}</div>
              </div>
            </div>
          </ng-container>
        </div>

        <div *ngIf="item.children" class="expand-icon"></div>
      </a>

      <ul *ngIf="item.children && item.isOpen" class="children" [class.twoColumns]="item.children?.length > 2" [@isItemOpen]="item.isOpen && !isMobileMenuOpen">
        <div class="back" (click)="item.isOpen = false">
          {{ item.name }}
        </div>

        <li *ngFor="let child of item.children">
          <a [routerLink]="child.url" routerLinkActive="active">
            <div class="icon">
              <img [src]="child.icon">
            </div>

            <div>
              {{ child.name }}
              <div class="description">{{ child.description }}</div>
            </div>
          </a>
        </li>
      </ul>
    </li>
  </ul>

  <div class="close" (click)="isMobileMenuOpen = false"></div>
</nav>
