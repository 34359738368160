export interface PhonePrefix {
  id: number;
  country: string;
  prefix: string;
}

export const UK_PHONE_PREFIX: PhonePrefix = { id: 284, country: "United Kingdom", prefix: "+44" };

export const PHONE_PREFIXES: PhonePrefix[] = [
  UK_PHONE_PREFIX,
  { id: 43, country: "Canada / United States", prefix: "+1" },
  { id: 1, country: "Afghanistan", prefix: "+93" },
  { id: 2, country: "Albania", prefix: "+355" },
  { id: 3, country: "Algeria", prefix: "+213" },
  { id: 4, country: "American Samoa", prefix: "+1 684" },
  { id: 5, country: "Andorra", prefix: "+376" },
  { id: 6, country: "Angola", prefix: "+244" },
  { id: 7, country: "Anguilla", prefix: "+1 264" },
  { id: 8, country: "Antigua and Barbuda", prefix: "+1 268" },
  { id: 9, country: "Argentina", prefix: "+54" },
  { id: 10, country: "Armenia", prefix: "+374" },
  { id: 11, country: "Aruba", prefix: "+297" },
  { id: 12, country: "Ascension", prefix: "+247" },
  { id: 13, country: "Australia", prefix: "+61" },
  { id: 14, country: "Australian Antarctic Territory", prefix: "+672 1" },
  { id: 15, country: "Australian External Territories", prefix: "+672" },
  { id: 16, country: "Austria", prefix: "+43" },
  { id: 17, country: "Azerbaijan", prefix: "+994" },
  { id: 18, country: "Bahamas", prefix: "+1 242" },
  { id: 19, country: "Bahrain", prefix: "+973" },
  { id: 20, country: "Bangladesh", prefix: "+880" },
  { id: 21, country: "Barbados", prefix: "+1 246" },
  { id: 22, country: "Barbuda", prefix: "+1 268" },
  { id: 23, country: "Belarus", prefix: "+375" },
  { id: 24, country: "Belgium", prefix: "+32" },
  { id: 25, country: "Belize", prefix: "+501" },
  { id: 26, country: "Benin", prefix: "+229" },
  { id: 27, country: "Bermuda", prefix: "+1 441" },
  { id: 28, country: "Bhutan", prefix: "+975" },
  { id: 29, country: "Bolivia", prefix: "+591" },
  { id: 30, country: "Bonaire", prefix: "+599 7" },
  { id: 31, country: "Bosnia and Herzegovina", prefix: "+387" },
  { id: 32, country: "Botswana", prefix: "+267" },
  { id: 33, country: "Brazil", prefix: "+55" },
  { id: 34, country: "British Indian Ocean Territory", prefix: "+246" },
  { id: 35, country: "British Virgin Islands", prefix: "+1 284" },
  { id: 36, country: "Brunei Darussalam", prefix: "+673" },
  { id: 37, country: "Bulgaria", prefix: "+359" },
  { id: 38, country: "Burkina Faso", prefix: "+226" },
  { id: 39, country: "Burundi", prefix: "+257" },
  { id: 40, country: "Cabo Verde", prefix: "+238" },
  { id: 41, country: "Cambodia", prefix: "+855" },
  { id: 42, country: "Cameroon", prefix: "+237" },
  { id: 44, country: "Caribbean Netherlands", prefix: "+599 3" },
  { id: 45, country: "Caribbean Netherlands", prefix: "+599 4" },
  { id: 46, country: "Caribbean Netherlands", prefix: "+599 7" },
  { id: 47, country: "Cayman Islands", prefix: "+1 345" },
  { id: 48, country: "Central African Republic", prefix: "+236" },
  { id: 49, country: "Chad", prefix: "+235" },
  { id: 50, country: "Chatham Island, New Zealand", prefix: "+64" },
  { id: 51, country: "Chile", prefix: "+56" },
  { id: 52, country: "China", prefix: "+86" },
  { id: 53, country: "Christmas Island", prefix: "+61 89164" },
  { id: 54, country: "Cocos (Keeling) Islands", prefix: "+61 89162" },
  { id: 55, country: "Colombia", prefix: "+57" },
  { id: 56, country: "Comoros", prefix: "+269" },
  { id: 57, country: "Congo", prefix: "+242" },
  { id: 58, country: "Congo, Democratic Republic of the (Zaire)", prefix: "+243" },
  { id: 59, country: "Cook Islands", prefix: "+682" },
  { id: 60, country: "Costa Rica", prefix: "+506" },
  { id: 61, country: "Croatia", prefix: "+385" },
  { id: 62, country: "Cuba", prefix: "+53" },
  { id: 63, country: "Curaçao", prefix: "+599 9" },
  { id: 64, country: "Cyprus", prefix: "+357" },
  { id: 65, country: "Czech Republic", prefix: "+420" },
  { id: 66, country: "Denmark", prefix: "+45" },
  { id: 67, country: "Diego Garcia", prefix: "+246" },
  { id: 68, country: "Djibouti", prefix: "+253" },
  { id: 69, country: "Dominica", prefix: "+1 767" },
  { id: 70, country: "Dominican Republic", prefix: "+1 809" },
  { id: 71, country: "Dominican Republic", prefix: "+1 829" },
  { id: 72, country: "Dominican Republic", prefix: "+1 849" },
  { id: 73, country: "East Timor", prefix: "+670" },
  { id: 74, country: "Easter Island", prefix: "+56" },
  { id: 75, country: "Ecuador", prefix: "+593" },
  { id: 76, country: "Egypt", prefix: "+20" },
  { id: 77, country: "El Salvador", prefix: "+503" },
  { id: 78, country: "Ellipso (Mobile Satellite service)", prefix: "+881 2" },
  { id: 79, country: "Ellipso (Mobile Satellite service)", prefix: "+881 3" },
  { id: 80, country: "EMSAT (Mobile Satellite service)", prefix: "+882 13" },
  { id: 81, country: "Equatorial Guinea", prefix: "+240" },
  { id: 82, country: "Eritrea", prefix: "+291" },
  { id: 83, country: "Estonia", prefix: "+372" },
  { id: 84, country: "Ethiopia", prefix: "+251" },
  { id: 85, country: "Falkland Islands", prefix: "+500" },
  { id: 86, country: "Faroe Islands", prefix: "+298" },
  { id: 87, country: "Fiji", prefix: "+679" },
  { id: 88, country: "Finland", prefix: "+358" },
  { id: 89, country: "France", prefix: "+33" },
  { id: 90, country: "French Antilles", prefix: "+596" },
  { id: 91, country: "French Guiana", prefix: "+594" },
  { id: 92, country: "French Polynesia", prefix: "+689" },
  { id: 93, country: "Gabon", prefix: "+241" },
  { id: 94, country: "Gambia", prefix: "+220" },
  { id: 95, country: "Georgia", prefix: "+995" },
  { id: 96, country: "Germany", prefix: "+49" },
  { id: 97, country: "Ghana", prefix: "+233" },
  { id: 98, country: "Gibraltar", prefix: "+350" },
  { id: 99, country: "Global Mobile Satellite System (GMSS)", prefix: "+881" },
  { id: 100, country: "Globalstar (Mobile Satellite Service)", prefix: "+881 8" },
  { id: 101, country: "Globalstar (Mobile Satellite Service)", prefix: "+881 9" },
  { id: 102, country: "Greece", prefix: "+30" },
  { id: 103, country: "Greenland", prefix: "+299" },
  { id: 104, country: "Grenada", prefix: "+1 473" },
  { id: 105, country: "Guadeloupe", prefix: "+590" },
  { id: 106, country: "Guam", prefix: "+1 671" },
  { id: 107, country: "Guatemala", prefix: "+502" },
  { id: 108, country: "Guernsey", prefix: "+44 1481" },
  { id: 109, country: "Guernsey", prefix: "+44 7781" },
  { id: 110, country: "Guernsey", prefix: "+44 7839" },
  { id: 111, country: "Guernsey", prefix: "+44 7911" },
  { id: 112, country: "Guinea", prefix: "+224" },
  { id: 113, country: "Guinea-Bissau", prefix: "+245" },
  { id: 114, country: "Guyana", prefix: "+592" },
  { id: 115, country: "Haiti", prefix: "+509" },
  { id: 116, country: "Honduras", prefix: "+504" },
  { id: 117, country: "Hong Kong", prefix: "+852" },
  { id: 118, country: "Hungary", prefix: "+36" },
  { id: 119, country: "Iceland", prefix: "+354" },
  { id: 120, country: "ICO Global (Mobile Satellite Service)", prefix: "+881 0" },
  { id: 121, country: "ICO Global (Mobile Satellite Service)", prefix: "+881 1" },
  { id: 122, country: "India", prefix: "+91" },
  { id: 123, country: "Indonesia", prefix: "+62" },
  { id: 124, country: "Inmarsat SNAC", prefix: "+870" },
  { id: 125, country: "International Freephone Service (UIFN)", prefix: "+800" },
  { id: 126, country: "International Networks", prefix: "+882" },
  { id: 127, country: "International Networks", prefix: "+883" },
  { id: 128, country: "International Premium Rate Service", prefix: "+979" },
  { id: 129, country: "International Shared Cost Service (ISCS)", prefix: "+808" },
  { id: 130, country: "Iran", prefix: "+98" },
  { id: 131, country: "Iraq", prefix: "+964" },
  { id: 132, country: "Ireland", prefix: "+353" },
  { id: 133, country: "Iridium (Mobile Satellite service)", prefix: "+881 6" },
  { id: 134, country: "Iridium (Mobile Satellite service)", prefix: "+881 7" },
  { id: 135, country: "Isle of Man", prefix: "+44 1624" },
  { id: 136, country: "Isle of Man", prefix: "+44 7524" },
  { id: 137, country: "Isle of Man", prefix: "+44 7624" },
  { id: 138, country: "Isle of Man", prefix: "+44 7924" },
  { id: 139, country: "Israel", prefix: "+972" },
  { id: 140, country: "Italy", prefix: "+39" },
  { id: 141, country: "Ivory Coast", prefix: "+225" },
  { id: 142, country: "Jamaica", prefix: "+1 876" },
  { id: 143, country: "Jan Mayen", prefix: "+47 79" },
  { id: 144, country: "Japan", prefix: "+81" },
  { id: 145, country: "Jersey", prefix: "+44 1534" },
  { id: 146, country: "Jordan", prefix: "+962" },
  { id: 147, country: "Kazakhstan", prefix: "+7 6" },
  { id: 148, country: "Kazakhstan", prefix: "+7 7" },
  { id: 149, country: "Kenya", prefix: "+254" },
  { id: 150, country: "Kiribati", prefix: "+686" },
  { id: 151, country: "Korea, North", prefix: "+850" },
  { id: 152, country: "Korea, South", prefix: "+82" },
  { id: 153, country: "Kosovo", prefix: "+383" },
  { id: 154, country: "Kuwait", prefix: "+965" },
  { id: 155, country: "Kyrgyzstan", prefix: "+996" },
  { id: 156, country: "Laos", prefix: "+856" },
  { id: 157, country: "Latvia", prefix: "+371" },
  { id: 158, country: "Lebanon", prefix: "+961" },
  { id: 159, country: "Lesotho", prefix: "+266" },
  { id: 160, country: "Liberia", prefix: "+231" },
  { id: 161, country: "Libya", prefix: "+218" },
  { id: 162, country: "Liechtenstein", prefix: "+423" },
  { id: 163, country: "Lithuania", prefix: "+370" },
  { id: 164, country: "Luxembourg", prefix: "+352" },
  { id: 165, country: "Macau", prefix: "+853" },
  { id: 166, country: "Macedonia", prefix: "+389" },
  { id: 167, country: "Madagascar", prefix: "+261" },
  { id: 168, country: "Malawi", prefix: "+265" },
  { id: 169, country: "Malaysia", prefix: "+60" },
  { id: 170, country: "Maldives", prefix: "+960" },
  { id: 171, country: "Mali", prefix: "+223" },
  { id: 172, country: "Malta", prefix: "+356" },
  { id: 173, country: "Marshall Islands", prefix: "+692" },
  { id: 174, country: "Martinique", prefix: "+596" },
  { id: 175, country: "Mauritania", prefix: "+222" },
  { id: 176, country: "Mauritius", prefix: "+230" },
  { id: 177, country: "Mayotte", prefix: "+262 269" },
  { id: 178, country: "Mayotte", prefix: "+262 639" },
  { id: 179, country: "Mexico", prefix: "+52" },
  { id: 180, country: "Micronesia, Federated States of", prefix: "+691" },
  { id: 181, country: "Midway Island, USA", prefix: "+1 808" },
  { id: 182, country: "Moldova", prefix: "+373" },
  { id: 183, country: "Monaco", prefix: "+377" },
  { id: 184, country: "Mongolia", prefix: "+976" },
  { id: 185, country: "Montenegro", prefix: "+382" },
  { id: 186, country: "Montserrat", prefix: "+1 664" },
  { id: 187, country: "Morocco", prefix: "+212" },
  { id: 188, country: "Mozambique", prefix: "+258" },
  { id: 189, country: "Myanmar", prefix: "+95" },
  { id: 190, country: "Nagorno-Karabakh", prefix: "+374 47" },
  { id: 191, country: "Nagorno-Karabakh", prefix: "+374 97" },
  { id: 192, country: "Namibia", prefix: "+264" },
  { id: 193, country: "Nauru", prefix: "+674" },
  { id: 194, country: "Nepal", prefix: "+977" },
  { id: 195, country: "Netherlands", prefix: "+31" },
  { id: 196, country: "Nevis", prefix: "+1 869" },
  { id: 197, country: "New Caledonia", prefix: "+687" },
  { id: 198, country: "New Zealand", prefix: "+64" },
  { id: 199, country: "Nicaragua", prefix: "+505" },
  { id: 200, country: "Niger", prefix: "+227" },
  { id: 201, country: "Nigeria", prefix: "+234" },
  { id: 202, country: "Niue", prefix: "+683" },
  { id: 203, country: "Norfolk Island", prefix: "+672 3" },
  { id: 204, country: "Northern Cyprus", prefix: "+90 392" },
  { id: 205, country: "Northern Ireland", prefix: "+44 28" },
  { id: 206, country: "Northern Mariana Islands", prefix: "+1 670" },
  { id: 207, country: "Norway", prefix: "+47" },
  { id: 208, country: "Oman", prefix: "+968" },
  { id: 209, country: "Pakistan", prefix: "+92" },
  { id: 210, country: "Palau", prefix: "+680" },
  { id: 211, country: "Palestine, State of", prefix: "+970" },
  { id: 212, country: "Panama", prefix: "+507" },
  { id: 213, country: "Papua New Guinea", prefix: "+675" },
  { id: 214, country: "Paraguay", prefix: "+595" },
  { id: 215, country: "Peru", prefix: "+51" },
  { id: 216, country: "Philippines", prefix: "+63" },
  { id: 217, country: "Pitcairn Islands", prefix: "+64" },
  { id: 218, country: "Poland", prefix: "+48" },
  { id: 219, country: "Portugal", prefix: "+351" },
  { id: 220, country: "Puerto Rico", prefix: "+1 787" },
  { id: 221, country: "Puerto Rico", prefix: "+1 939" },
  { id: 222, country: "Qatar", prefix: "+974" },
  { id: 223, country: "Romania", prefix: "+40" },
  { id: 224, country: "Russia", prefix: "+7" },
  { id: 225, country: "Rwanda", prefix: "+250" },
  { id: 226, country: "Réunion", prefix: "+262" },
  { id: 227, country: "Saba", prefix: "+599 4" },
  { id: 228, country: "Saint Barthélemy", prefix: "+590" },
  { id: 229, country: "Saint Helena", prefix: "+290" },
  { id: 230, country: "Saint Kitts and Nevis", prefix: "+1 869" },
  { id: 231, country: "Saint Lucia", prefix: "+1 758" },
  { id: 232, country: "Saint Martin (France)", prefix: "+590" },
  { id: 233, country: "Saint Pierre and Miquelon", prefix: "+508" },
  { id: 234, country: "Saint Vincent and the Grenadines", prefix: "+1 784" },
  { id: 235, country: "Samoa", prefix: "+685" },
  { id: 236, country: "San Marino", prefix: "+378" },
  { id: 237, country: "Saudi Arabia", prefix: "+966" },
  { id: 238, country: "Senegal", prefix: "+221" },
  { id: 239, country: "Serbia", prefix: "+381" },
  { id: 240, country: "Seychelles", prefix: "+248" },
  { id: 241, country: "Sierra Leone", prefix: "+232" },
  { id: 242, country: "Singapore", prefix: "+65" },
  { id: 243, country: "Sint Eustatius", prefix: "+599 3" },
  { id: 244, country: "Sint Maarten (Netherlands)", prefix: "+1 721" },
  { id: 245, country: "Slovakia", prefix: "+421" },
  { id: 246, country: "Slovenia", prefix: "+386" },
  { id: 247, country: "Solomon Islands", prefix: "+677" },
  { id: 248, country: "Somalia", prefix: "+252" },
  { id: 249, country: "South Africa", prefix: "+27" },
  { id: 250, country: "South Georgia and the South Sandwich Islands", prefix: "+500" },
  { id: 251, country: "South Ossetia", prefix: "+995 34" },
  { id: 252, country: "South Sudan", prefix: "+211" },
  { id: 253, country: "Spain", prefix: "+34" },
  { id: 254, country: "Sri Lanka", prefix: "+94" },
  { id: 255, country: "Sudan", prefix: "+249" },
  { id: 256, country: "Suriname", prefix: "+597" },
  { id: 257, country: "Svalbard", prefix: "+47 79" },
  { id: 258, country: "Swaziland", prefix: "+268" },
  { id: 259, country: "Sweden", prefix: "+46" },
  { id: 260, country: "Switzerland", prefix: "+41" },
  { id: 261, country: "Syria", prefix: "+963" },
  { id: 262, country: "São Tomé and Príncipe", prefix: "+239" },
  { id: 263, country: "Taiwan", prefix: "+886" },
  { id: 264, country: "Tajikistan", prefix: "+992" },
  { id: 265, country: "Tanzania", prefix: "+255" },
  { id: 266, country: "Telecommunications for Disaster Relief by OCHA", prefix: "+888" },
  { id: 267, country: "Thailand", prefix: "+66" },
  { id: 268, country: "Thuraya (Mobile Satellite service)", prefix: "+882 16" },
  { id: 269, country: "Togo", prefix: "+228" },
  { id: 270, country: "Tokelau", prefix: "+690" },
  { id: 271, country: "Tonga", prefix: "+676" },
  { id: 272, country: "Transnistria", prefix: "+373 2" },
  { id: 273, country: "Transnistria", prefix: "+373 5" },
  { id: 274, country: "Trinidad and Tobago", prefix: "+1 868" },
  { id: 275, country: "Tristan da Cunha", prefix: "+290 8" },
  { id: 276, country: "Tunisia", prefix: "+216" },
  { id: 277, country: "Turkey", prefix: "+90" },
  { id: 278, country: "Turkmenistan", prefix: "+993" },
  { id: 279, country: "Turks and Caicos Islands", prefix: "+1 649" },
  { id: 280, country: "Tuvalu", prefix: "+688" },
  { id: 281, country: "Uganda", prefix: "+256" },
  { id: 282, country: "Ukraine", prefix: "+380" },
  { id: 283, country: "United Arab Emirates", prefix: "+971" },
  { id: 286, country: "Universal Personal Telecommunications (UPT)", prefix: "+878" },
  { id: 287, country: "Uruguay", prefix: "+598" },
  { id: 288, country: "US Virgin Islands", prefix: "+1 340" },
  { id: 289, country: "Uzbekistan", prefix: "+998" },
  { id: 290, country: "Vanuatu", prefix: "+678" },
  { id: 291, country: "Vatican City State (Holy See)", prefix: "+39 06 698" },
  { id: 292, country: "Vatican City State (Holy See) assigned", prefix: "+379" },
  { id: 293, country: "Venezuela", prefix: "+58" },
  { id: 294, country: "Vietnam", prefix: "+84" },
  { id: 295, country: "Wake Island, USA", prefix: "+1 808" },
  { id: 296, country: "Wallis and Futuna", prefix: "+681" },
  { id: 297, country: "Yemen", prefix: "+967" },
  { id: 298, country: "Zambia", prefix: "+260" },
  { id: 299, country: "Zanzibar", prefix: "+255 24" },
  { id: 300, country: "Zimbabwe", prefix: "+263" },
  { id: 301, country: "Åland Islands", prefix: "+358 18" },
];
