import { Component, Input } from '@angular/core';
import { SideMenuItem } from '../../model';

@Component({
  selector: 'vc-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent {
  @Input() menuItems: SideMenuItem[] = [];
  @Input() title: string = '';
}
