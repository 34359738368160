import { Directive, ElementRef, Input } from "@angular/core";

@Directive({
  selector: "[vcSrcset]",
})
export class SrcsetDirective {
  /**
   * Expects image filename ending with `@xN.jpeg` where `N` will be replaced with 1,2,3 and used
   * in [src] and [srcset] attributes.
   */
  @Input()
  public set vcSrcset(url: string) {
    this.srcset = url;
    this.compose();
  }

  private srcset: string;

  constructor(private el: ElementRef) { }

  private compose(): void {
    if (this.srcset) {
      const sizes: string[] = [1, 2, 3].map(n => this.srcset.replace("@xN.", `@x${n}.`));

      this.el.nativeElement.src = sizes[0];
      this.el.nativeElement.srcset = `${sizes[1]} 2x, ${sizes[2]} 3x`;
    } else {
      this.el.nativeElement.src = null;
      this.el.nativeElement.srcset = null;
    }
  }
}
