import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { SortDirection } from '../../model';

interface ColumnData {
  name: string;
  align?: 'left' | 'center' | 'right';
  customDataField?: string;
  label: string;
  additionalHeaderClasses?: string;
  additionalColumnClasses?: string;
}

interface SortDataEvent {
    key: string;
    direction: SortDirection;
}
@Component({
  selector: 'vc-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnDestroy {
  @Input() columns: ColumnData[];
  @Input() data: any[];
  @Input() sortable = false; // Enable/disable FE sorting
  @Input() sortKeys: string[] = []; // Array of keys to sort the data by
  @Input() sortDirections: { [key: string]: SortDirection } = {}; // Sort directions for each key
  @Input() customTemplates: { [key: string]: any } = {}; // custom templates for specific columns
  @Input() actionTemplate?: TemplateRef<any>;
  @Input() infiniteScroll = false;
  @Input() isLoadingData = true;

  @Output() sortDataClicked: EventEmitter<SortDataEvent> = new EventEmitter();
  @Output() loadMore: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('spinner') spinnerElement: ElementRef;

  private intersectionObserver: IntersectionObserver;


  ngAfterViewInit() {
    // Initialize IntersectionObserver
    this.intersectionObserver = new IntersectionObserver(
      (entries) => {
        this.handleIntersection(entries);
      },
      { threshold: 1 } // Trigger when the spinner is fully visible
    );

    // Observe the spinner element
    this.intersectionObserver.observe(this.spinnerElement.nativeElement);
  }

  ngOnDestroy() {
    this.intersectionObserver.disconnect();
  }
  
  onSort(key: string) {
    if (this.sortKeys.includes(key)) {
      this.sortDirections[key] =
        this.sortDirections[key] === 'ASC' ? 'DESC' : 'ASC';
      if (this.sortable) {
        this.sortData();
      } else {
        this.sortDataClicked.emit({ key, direction: this.sortDirections[key] });
      }
    }
  }

  handleIntersection(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.loadMore.emit();
      }
    });
  }
  
  private sortData() {
    if (this.sortKeys.length > 0) {
      this.data.sort((a, b) => {
        for (const key of this.sortKeys) {
          const valueA = a[key];
          const valueB = b[key];
          if (this.sortDirections[key] === 'ASC') {
            const result = valueA.localeCompare(valueB);
            if (result !== 0) {
              return result;
            }
          } else {
            const result = valueB.localeCompare(valueA);
            if (result !== 0) {
              return result;
            }
          }
        }
        return 0;
      });
    }
  }
}
